var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0 projects-table",attrs:{"headers":_vm.headers,"items":_vm.assignments,"items-per-page":10},scopedSlots:_vm._u([{key:"item.DESCRIPCION_ESTADO",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mb-0",attrs:{"small":"","color":item.ESTADO == '2' ? 'green' : 'red'}},[_vm._v(_vm._s(item.DESCRIPCION_ESTADO))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('CrudButtons',{attrs:{"noEdit":true,"noDelete":_vm.assigmentId === 0
            ? item.PENDIENTEDEANULACION == 0 || _vm.isToday === false
            : item.PENDIENTEDEANULACION == 1 ||
              item.ESTADO == 4 ||
              _vm.isToday === false,"loadingDelete":item.IDCOMPRA === _vm.loadingDelete},on:{"remove":function($event){return _vm.$emit('openDelete', item)}}})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }