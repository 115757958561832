<template>
  <div class="projects mb-4" v-if="idAsignacion == 0">
    <header class="projects-header">
      <TitleSection
        :title="'Ventas de Boleterías'"
        :subtitle="
          'Gestiona las ventas de las boleterías según sus asignaciones en la fecha'
        "
      />
    </header>
    <div class="row py-4">
      <div class="col-lg-3 col-md-3 col-sm-12 pb-2 pt-0">
        <v-text-field
          label="Fecha fin de asignación"
          type="date"
          :rounded="rounded"
          :color="color"
          :dark="dark"
          :background-color="bgColor"
          :rules="required"
          :disabled="disabled"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :autocomplete="autocomplete"
          single-line
          @change="getPurchasesByDate"
          v-model="assignment.date"
        >
        </v-text-field>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 pb-2 pt-0">
        <v-autocomplete
          label="Boletería"
          :rounded="rounded"
          :color="color"
          :dark="dark"
          :background-color="bgColor"
          :rules="required"
          :disabled="disabled || loading"
          :loading="loading"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :autocomplete="autocomplete"
          single-line
          :items="boxOffices"
          item-text="DESCRIPCION"
          :multiple="false"
          return-object
          small-chips
          @change="getPurchases"
          v-model="assignment.boxOffice"
        >
        </v-autocomplete>
      </div>
    </div>

    <TablePurchasesAssignments
      :assigmentId="idAsignacion"
      :assignments="assignments"
      @openDelete="removeAssignment"
    >
    </TablePurchasesAssignments>
  </div>
  <div v-else class="template-tickets">
    <v-container>
      <header class="projects-header">
        <TitleSection
          :title="'Mis Ventas'"
          :subtitle="'Gestiona las ventas de la boletería'"
        />
      </header>
      <div class="row py-4">
        <div class="col-lg-3 col-md-3 col-sm-12 pb-2 pt-0">
          <v-text-field
            label="Fecha fin de asignación"
            type="date"
            :rounded="rounded"
            :color="color"
            :dark="dark"
            :background-color="bgColor"
            :rules="required"
            :disabled="idAsignacion > 0"
            :hide-details="details"
            :outlined="outlined"
            :filled="filled"
            :dense="dense"
            :autocomplete="autocomplete"
            single-line
            @change="getPurchasesByDate"
            v-model="assignment.date"
          >
          </v-text-field>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 pb-2 pt-0">
          <v-autocomplete
            label="Boletería"
            :rounded="rounded"
            :color="color"
            :dark="dark"
            :background-color="bgColor"
            :rules="required"
            :disabled="disabled || loading || idAsignacion > 0"
            :loading="loading"
            :hide-details="details"
            :outlined="outlined"
            :filled="filled"
            :dense="dense"
            :autocomplete="autocomplete"
            single-line
            :items="boxOffices"
            item-text="DESCRIPCION"
            :multiple="false"
            return-object
            small-chips
            @change="getPurchases"
            v-model="assignment.boxOffice"
          >
          </v-autocomplete>
        </div>
      </div>

      <TablePurchasesAssignments
        :assigmentId="idAsignacion"
        :assignments="assignments"
        :loadingDelete="loadingDelete"
        :isToday="assignment.date === today"
        @openDelete="preRemoveAssignment"
      >
      </TablePurchasesAssignments>
    </v-container>
  </div>
</template>

<script>
import TitleSection from "@/components/titles/TitleSection";
import TablePurchasesAssignments from "@/components/tables/TablePurchasesAssignments";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import moment from "moment-timezone";
import axios from "@/api/entradaslima";
import store from "../../store";
export default {
  components: {
    TitleSection,
    TablePurchasesAssignments
  },
  data() {
    return {
      rounded: false,
      color: "#738297",
      bgColor: "#ffffff",
      required: [v => !!v || "Requerido"],
      upToZero: [v => (!!v && v > 0) || "Incorrecto"],
      disabled: false,
      details: true,
      filled: true,
      outlined: false,
      dense: true,
      autocomplete: "off",
      readonly: true,
      dark: false,
      dialog: false,
      loading: false,
      loadingDelete: -1,
      loadingReport: false,
      assignment: {
        boxOffice: {},
        date: moment()
          .tz("America/Lima")
          .format("YYYY-MM-DD")
      },
      idParque: store.getters.getSessionParqueId,
      usuario: store.getters.getSessionUser.CORREO,
      idAsignacion: store.getters.getSessionIdAsignacion || 0,
      assignments: [],
      boxOffices: [],
      users: [],
      boxClosed: null,
      today: moment()
        .tz("America/Lima")
        .format("YYYY-MM-DD")
    };
  },
  computed: {
    getTitle() {
      return "Nueva Asignación";
    },
    getSubtitle() {
      return "Rellena todos los campos para crear una nueva asignación";
    }
  },
  async mounted() {
    await this.getPurchasesByDate();
    if (this.idAsignacion > 0) {
      await this.getBoxOpening();
    } else {
      alert(12);
    }
  },
  methods: {
    ...mapActions({
      generateConfirmDialog: "generateConfirmDialog",
      generateSnackBarMessage: "generateSnackBarMessage"
    }),
    async getPurchasesByDate() {
      try {
        this.loading = true;
        this.assignment.boxOffice = {};
        this.assignments = [];
        const { data, status } = await axios.get(
          `/parques/${this.idParque}/boleterias/0/asignaciones?fecha=${this.assignment.date}`
        );
        this.loading = false;
        if (status != 200) return;
        this.boxOffices =
          this.idAsignacion === 0
            ? data.data.filter(item => item.ESTADO === 1)
            : data.data
                .filter(item => item.ESTADO === 1)
                .filter(item => item.IDASIGNACION === this.idAsignacion);
        if (this.idAsignacion > 0) {
          this.assignment.boxOffice = this.boxOffices.find(
            (item, index) => index === 0
          );
          this.getPurchases();
        }
      } catch (error) {
        this.loading = false;
        return;
      }
    },
    async getPurchases() {
      try {
        const { data } = await axios.get(
          `/parques/${this.idParque}/asignaciones/${this.assignment.boxOffice
            .IDASIGNACION || "0"}/ventas`
        );
        this.assignments = data.data;
      } catch (error) {
        return Swal.fire({
          icon: "error",
          text: error.response.data.message || "Ocurrió un error interno"
        });
      }
    },
    async saveAssignment(dto) {
      try {
        const { data } = await axios.post(
          `/parques/${this.idParque}/boleterias/${dto.boxOffice.IDBOLETERIA}/asignaciones`,
          {
            idUsuario: dto.user.IDUSUARIO,
            usuario: this.usuario
          }
        );
        this.closeDialog();
        this.generateSnackBarMessage({
          message: data.message
        });
        await this.getPurchases();
      } catch (error) {
        return Swal.fire({
          icon: "error",
          text: error.response.data.message || "Ocurrió un error interno"
        });
      }
    },
    async preRemoveAssignment(dto) {
      if ((this.boxClosed?.IDESTADOBOLETERIA || 0) === 0) {
        const response = await this.generateConfirmDialog({
          message: "¿Esta seguro de confirmar la pre-anulación?",
          confirmMessage: "Si, quiero pre anular"
        });
        if (response) {
          try {
            this.loadingDelete = dto.IDCOMPRA;
            const { data } = await axios.put(
              `/parques/${this.idParque}/compras/${dto.IDCOMPRA}/preanular`,
              {
                idSistema: dto.IDSISTEMA
              }
            );
            this.loadingDelete = -1;
            this.generateSnackBarMessage({
              message: data.message
            });
            await this.getPurchases();
          } catch (error) {
            this.loadingDelete = -1;
            return Swal.fire({
              icon: "error",
              text: error.response.data.message || "Ocurrió un error interno"
            });
          }
        }
      } else {
        return Swal.fire({
          icon: "error",
          text: "La caja ya se encuentra cerrada"
        });
      }
    },
    async removeAssignment(dto) {
      const response = await this.generateConfirmDialog({
        message: "¿Esta seguro de confirmar la anulación?",
        confirmMessage: "Si, quiero eliminar"
      });
      if (response) {
        try {
          const { data } = await axios.put(
            `/parques/${this.idParque}/compras/${dto.IDCOMPRA}/cambioestado`,
            {
              idioma: "es",
              idSistema: dto.IDSISTEMA,
              idEstado: 4,
              idFormaPago: 0,
              metadata: {}
            }
          );
          this.generateSnackBarMessage({
            message: data.message
          });
          await this.getPurchases();
        } catch (error) {
          return Swal.fire({
            icon: "error",
            text: error.response.data.message || "Ocurrió un error interno"
          });
        }
      }
    },
    async getBoxOpening() {
      const { data, status } = await axios.get(
        `/parques/${this.idParque}/asignaciones/${this.idAsignacion}/estados`
      );
      if (status != 200) return;
      this.boxClosed =
        data.data
          .filter(item => item.ESTADO === 1)
          .find(item => item.TIPOESTADO === "CF") || {};
    }
  }
};
</script>

<style lang="scss" scoped></style>
