<template>
  <v-data-table
    :headers="headers"
    :items="assignments"
    :items-per-page="10"
    class="elevation-0 projects-table"
  >
    <template v-slot:[`item.DESCRIPCION_ESTADO`]="{ item }">
      <v-chip
        class="mb-0"
        small
        :color="item.ESTADO == '2' ? 'green' : 'red'"
        >{{ item.DESCRIPCION_ESTADO }}</v-chip
      >
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex justify-content-end">
        <CrudButtons
          :noEdit="true"
          :noDelete="
            assigmentId === 0
              ? item.PENDIENTEDEANULACION == 0 || isToday === false
              : item.PENDIENTEDEANULACION == 1 ||
                item.ESTADO == 4 ||
                isToday === false
          "
          :loadingDelete="item.IDCOMPRA === loadingDelete"
          @remove="$emit('openDelete', item)"
        ></CrudButtons>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import CrudButtons from "@/components/buttons/CrudButtons";
export default {
  components: {
    CrudButtons
  },
  props: {
    assigmentId: Number,
    assignments: Array,
    loadingDelete: Number,
    isToday: Boolean
  },
  data() {
    return {
      headers: [
        {
          text: "Id",
          align: "center",
          sortable: false,
          value: "IDCOMPRA"
        },
        {
          text: "Categoría",
          sortable: false,
          value: "DESCRIPCION_CATEGORIA" // cambiar value
        },
        {
          text: "Documento Identidad",
          sortable: false,
          align: "center",
          value: "NUMERODOCUMENTO" // cambiar value
        },
        {
          text: "Nombres",
          align: "start",
          sortable: false,
          value: "NOMBRES"
        },
        {
          text: "Apellidos",
          align: "start",
          sortable: false,
          value: "APELLIDOS"
        },
        {
          text: "Hora Registro",
          sortable: false,
          align: "center",
          value: "FECHA" // cambiar value
        },
        {
          text: "Forma de Pago",
          align: "end",
          sortable: false,
          value: "FORMAPAGO"
        },
        {
          text: "Importe",
          align: "end",
          sortable: false,
          value: "TOTAL"
        },
        {
          text: "Estado",
          align: "center",
          sortable: false,
          value: "DESCRIPCION_ESTADO" // cambiar value
        },
        {
          text: "¿Por Anular?",
          align: "center",
          sortable: false,
          value: "DESCRIPCION_PENDIENTEANULACION" // cambiar value
        },
        {
          text: "",
          sortable: false,
          value: "actions"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped></style>
